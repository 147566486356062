.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: 60px;
  border-bottom: 1px solid #ededed;
  background-color: #fff;

  &.nav-bar-dark {
    background-color: #000;
  }

  & .nav-bar-left,
  & .nav-bar-right {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
  }

  & .nav-bar-left {
    left: 20px;
  }
  & .nav-bar-right {
    right: 20px;
  }

  & .nav-bar-title {
    flex: 1;
  }
}
