*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

button,
textarea,
input {
  &:focus {
    outline: 0;
  }
}

ol,
ul,
li {
  list-style: none;
}

p,
strong,
figure,
figcaption,
ol,
ul,
li {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}
