.divider {
  &.divider-solid {
    height: 1px;
    background-color: #ededed;
  }

  &.divider-dotted {
    height: 1px;
    border-top: 1px dotted #dadada;
  }

  &.divider-dashed {
    height: 1px;
    border-top: 1px dashed #dadada;
  }

  &.divider-margin {
    margin: 12px 0;
  }

  &.divider-black {
    background-color: #000;
  }
}
