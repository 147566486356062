.carousel {
  position: relative;
  padding-bottom: 24px;

  /* carousel */
  & ol {
    display: flex;
    overflow-x: scroll;
    counter-reset: item;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    & li {
      overflow: hidden;
      position: relative;
      flex: 0 0 100%;
      width: 100%;
      counter-increment: item;
      scroll-snap-align: center;
    }
  }

  & figure {
    width: 100%;
    height: 223px;
  }

  & .carousel-indicator {
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 12px;
    border: solid 1px #000;
    background-color: #fff;
    padding: 3px 15px;
    text-align: center;
    color: #000;
  }

  & .carousel-caption {
    position: relative;
    border: solid 1px #ededed;
    border-radius: 16px;
    background-color: #fff;
    padding: 7px 16px;
    margin: -40px 16px 0;
  }

  & .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
