.image-viewer {
  color: #fff;
  height: 100vh;
  background-color: #000;

  & ol {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    counter-reset: item;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    & li {
      overflow: hidden;
      position: relative;
      flex: 0 0 100%;
      width: 100%;
      height: 100%;
      counter-increment: item;
      scroll-snap-align: center;
    }
  }

  & img {
    width: 100%;
  }
}
