.section {
  margin-bottom: 10px;
  background-color: #f7f7f7;

  &.section-border {
    border-bottom: 1px solid #ededed;
  }

  & .section-content {
    background-color: #fff;
    margin-bottom: 16px;
  }
}
