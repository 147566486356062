.screen {
  padding: 24px 20px;
  background-color: #f7f7f7;

  &.screen-navi {
    margin-top: 60px;
  }

  &.screen-stretch {
    padding: 0;
  }
}
