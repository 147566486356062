.flexbox {
  &.flexbox-row {
    flex-direction: row;
  }

  &.flexbox-col {
    flex-direction: column;
  }

  &.flexbox-space-between {
    justify-content: space-between;
  }

  &.flexbox-space-around {
    align-content: space-around;
  }

  &.flexbox-center {
    justify-content: center;
  }

  &.flexbox-middle {
    align-items: center;
  }
}
