:global(.t-txt-clr-black) {
  color: #000 !important;
}

:global(.t-txt-clr-white) {
  color: #fff !important;
}

:global(.t-txt-clr-blue) {
  color: #0068ff !important;
}

:global(.t-txt-clr-gray) {
  color: #222 !important;
}

:global(.t-txt-clr-gray-light) {
  color: #999 !important;
}

:global(.t-txt-sz-biggest) {
  font-size: 22px !important;
}

:global(.t-txt-sz-bigger) {
  font-size: 20px !important;
}

:global(.t-txt-sz-big) {
  font-size: 18px !important;
}

:global(.t-txt-sz-normal) {
  font-size: 16px !important;
}

:global(.t-txt-sz-small) {
  font-size: 14px !important;
}

:global(.t-txt-sz-smaller) {
  font-size: 12px !important;
}

:global(.t-txt-wt-bold) {
  font-weight: 600 !important;
}

:global(.t-txt-wt-normal) {
  font-weight: normal !important;
}
