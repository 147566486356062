.float-contents {
  position: fixed;
  top: 0;
  overflow: auto;
  z-index: 9999;
  height: 100vh;

  & main {
    background-color: #fff;
    color: #000;
    font-size: 12px;

    & h3 {
      font-size: 22px;
      font-weight: bold;
    }
  }
}
