.text-input-field {
  & input {
    -webkit-appearance: none;

    &:focus {
      border: 1px solid #000;
    }

    &::placeholder {
      font-size: 16px;
      color: #999;
    }
  }
}
