.title {
  margin-bottom: 8px;
  font-weight: 600;

  & .title-header {
  }

  & .title-more {
  }
}
