:global(.is-hidden) {
  display: none !important;
}

:global(.is-transparent) {
  background-color: transparent !important;
}

:global(.hidden-scrollbar) {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

:global(.blocked-scrolling) {
  overflow: hidden !important;
}
