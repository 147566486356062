.auto-suggest {
  position: relative;
  margin: 12px 0;

  & .auto-suggest-input {
    appearance: none;
    border: 1px solid #ededed;
    border-radius: 16px;
    background-color: #fff;
    padding: 12px 16px;
    margin: 8px 0 0;
    width: 100%;
    font-size: 16px;

    &:focus {
      border: 1px solid #000;
    }

    &::placeholder {
      font-size: 16px;
      color: #999;
    }
  }

  & .auto-suggest-label {
    font-weight: 600;
  }

  & .auto-suggest-input-tiggered {
    border-bottom: none;
    border-radius: 16px 16px 0 0;

    &:focus {
      border-bottom: none;
      border-radius: 16px 16px 0 0;
    }
  }

  & .auto-suggest-box {
    position: absolute;
    width: 100%;
    z-index: 99;
    background-color: #fff;
    padding: 0 12px 13px;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    border-radius: 0 0 16px 16px;

    & .auto-suggest-box-inner {
      padding-top: 12px;
      border-top: 1px solid #ededed;

      & li {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        cursor: pointer;
        margin: 8px 0;
      }
    }
  }
}
