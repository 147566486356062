:global(#app-root) {
}

:global(#modal-root) {
}

:global(.l-txt-align-center) {
  text-align: center;
}

:global(.l-txt-align-left) {
  text-align: left;
}

:global(.l-txt-align-right) {
  text-align: right;
}
