.textfield {
  margin: 12px 0;

  & .textfield-input {
    appearance: none;
    border: 1px solid #ededed;
    border-radius: 16px;
    background-color: #fff;
    padding: 12px 16px;
    margin-top: 8px;
    width: 100%;
    font-size: 16px;

    &:focus {
      border: 1px solid #000;
    }

    &::placeholder {
      font-size: 16px;
      color: #999;
    }
  }

  & .textfield-label {
    font-weight: 600;
  }
}
