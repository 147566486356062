.upload-image {
  position: relative;
  height: 223px;
  background-color: #f7f7f7;
  margin-bottom: 24px;

  & ol {
    display: flex;
    overflow-x: scroll;
    counter-reset: item;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    & li {
      overflow: hidden;
      position: relative;
      flex: 0 0 100%;
      width: 100%;
      height: 223px;
      counter-increment: item;
      scroll-snap-align: center;
    }
  }

  & .upload-image-counter {
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 12px;
    border: solid 1px #000;
    background-color: #fff;
    padding: 3px 15px;
    text-align: center;
    color: #000;
  }

  & .upload-image-trash-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    display: inline-block;
  }

  & .upload-image-file {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
