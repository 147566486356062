.tabs {
  border-bottom: 1px solid #ededed;
  background-color: #fff;

  & .tabs-btn {
    padding: 4px 0;
    margin: 0 28px;
    flex: 1;

    &:global(.is-selected) {
      border-bottom: 3px solid #000;
    }
  }
}
