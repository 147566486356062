.time-selection {
  & .sheet {
    & .meridiem,
    & .hours,
    & .mins {
      & > ul {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        counter-reset: item;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        -webkit-overflow-scrolling: touch;

        & li {
          width: 100%;
          line-height: 30px;
          font-size: 14px;
          color: #999;
          text-align: center;
          counter-increment: item;

          &:global(.selected) {
            width: 100%;
            text-align: center;
            line-height: 48px;
            font-weight: bold;
            font-size: 20px;
            color: #000;
            scroll-snap-align: center;
          }
        }
      }
    }
  }
}
