.textbox {
  & .textbox-textarea {
    min-height: calc(128px - 32px);
    appearance: none;
    resize: none;
    border-radius: 16px;
    border: 1px solid #ededed;
    width: 100%;
    font-size: 16px;
    padding: 16px;
    margin: 8px 0 0 0;

    &:focus {
      border: 1px solid #000;
    }

    &::placeholder {
      font-size: 16px;
      color: #999;
    }
  }

  & .textbox-label {
    font-weight: 600;
  }
}
