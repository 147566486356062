.progress {
  height: 4px;
  border-radius: 2px;
  background-color: #dadada;

  & .progress-bar {
    border-radius: 2px;
    background-color: #000;
  }
}
