/**
 * Modules CSS query (common)
 *
 * NOTE
 * | avoid using this unless necessary
 */

[class^='screen-'] {
  & [class^='section-'] {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}
