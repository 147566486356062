.jumbotron {
  & .carousel {
    overflow-x: scroll;
    counter-reset: item;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    & .carousel-item {
      counter-increment: item;
      scroll-snap-align: center;
      scroll-snap-stop: always;
    }
  }
}
